import { GetterTree, MutationTree, ActionTree } from 'vuex'
import {getList as getEndpoints} from "@/api/endpoints";
import {Endpoint} from "@/types";

class State {
  endpoints: Endpoint[]
  endpoint: Endpoint
}

const getters: GetterTree<State, any> = {
  endpoints: state => state.endpoints
}

const mutations: MutationTree<State> = {
  setEndpoints(state, payload: Endpoint[]){
    state.endpoints = payload
  },
  setEndpoint(state, payload: Endpoint) {
    state.endpoint = payload
  }
}

const actions: ActionTree<State, any> = {
  async init(context) {
    let endpoints = await getEndpoints();
    context.commit('setEndpoints', endpoints)
  },
}

export default {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
