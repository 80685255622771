import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { apiClient } from '@/api/client'

class State {} //NOSONAR

const getters: GetterTree<State, any> = {}

const mutations: MutationTree<State> = {}

const actions: ActionTree<State, any> = {
	async getLicense(context) {
		try {
			const { data } = await apiClient.get('license')
			console.log(data)
			if (data !== 'success') {
				context.commit('notifications/toast', {id: data}, { root: true })
			}
		}
		catch (e) {
			console.error(e)
			context.commit('notifications/toast', {id: e.name}, { root: true })
		}
		finally {
			setTimeout(() => context.dispatch('getLicense'), 60 * 60 * 1_000)
		}
	},
}

export default {
	namespaced: true,
	state: new State(),
	mutations: mutations,
	actions: actions,
	getters: getters,
}
