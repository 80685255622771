import Vue from 'vue'
import Vuex from 'vuex'

import asyncOperation from './modules/async-operation'
import proposed from './modules/proposed'
import notifications from './modules/notifications'
import afterImport from './modules/after-import'
import favorite from './modules/favorite'
import license from './modules/license'
import endpoints from './modules/endpoints'
import ontologySettings from './modules/ontology-settings'
import ontologyVersions from './modules/ontology-versions'
import auth from './modules/auth'
import config from './modules/config'
import cacheWatcher from './modules/cache-watcher'
import constants from './modules/constants'

Vue.use(Vuex)

class State {} //NOSONAR

export default new Vuex.Store({
	state: new State(),
	modules: {
		auth,
		asyncOperation,
		proposed,
		notifications,
		afterImport,
		favorite,
		license,
		endpoints,
		ontologySettings,
		ontologyVersions,
		config,
		cacheWatcher,
		constants
	},
})
